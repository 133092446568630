import React from 'react';
import _ from 'lodash';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import theme from '../../theme';

const styles = {
    height: {
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
    },
    assignModel: {
        margin: theme.spacing(1) * 4
    },
    padding: {
        marginTop: '80px',
        padding:'100px'
    }
};

class FbLoader extends React.Component {
    
    constructor(props) {
        super(props);
        this._id = _.uniqueId(this.props.Prefix ? this.props.Prefix : "loader");
        this.loaderStyle = this.props.loaderStyle != null ? this.props.loaderStyle : 'default';
    
    }

    shouldComponentUpdate(nextProps, nextState) {
        return false;
    }

    render() {
        const { classes,extraPadding } = this.props;
        const imgstyle = { "float": "none" }
        const style = { "textAlign": "center", "height": "300px", "verticalAlign": "middle" }
        const colspan = this.props.colSpan != null ? this.props.colSpan : 1;
        const extraPaddingAttr = extraPadding ? { className: classes.padding } : null;
    
        const base_style = (<img alt="Loading" style={imgstyle} src={require("../../assets/images/loader.gif")} width="48"  />);
        if (this.loaderStyle === 'default') {
            return base_style;
        }
        else if (this.loaderStyle === 'emptycell') {
            const common_td = (<tr><td colSpan={colspan} style={style}> {base_style}    </td></tr>);
            if (this.props.isTableBodyRequired === true) {
                return (<tbody>{common_td}</tbody>);
            }
            return common_td;
        }
        else if (this.loaderStyle === 'tableLoader') {
            const common_td = (<tr><td colSpan={colspan} style={style}> {base_style}    </td></tr>);
            return (
                <table>
                    <tbody>{common_td}</tbody>
                </table>
            );
        }
        else if (this.loaderStyle === 'center') {
            return (<Grid container
                direction="row"
                justifyContent="center"
                alignItems="center"
                {...extraPaddingAttr}
            >
                <Grid item className={classes.height}> {base_style }</Grid>
            </Grid>);
           
         
        }
        else if (this.loaderStyle === 'assignModel') {
            return (<Grid container
                direction="row"
                justify="center"
                alignItems="center" 
            >
               <Grid item className={classes.assignModel}> {base_style}</Grid>
            </Grid>);


        }
        return base_style;
    }
}

export default withStyles(styles)(FbLoader);