import React, { useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Button, Typography, Container, Paper } from '@material-ui/core';

import ProductSerialLedgerFilters from "./ProductSerialLedgerFilters";
import ProductSerialLedgerTable from "./ProductSerialLedgerTable";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        minHeight: '100vh',
        backgroundColor: theme.palette.grey[100],
        padding: theme.spacing(3),
    },
    content: {
        maxWidth: '100%',
        margin: '0 auto',
    },
    section: {
        marginBottom: theme.spacing(3),
    },
    tableSection: {
        minHeight: 500, // Ensure minimum height for table section
    },
    pagination: {
        marginTop: theme.spacing(2),
        padding: theme.spacing(2),
        backgroundColor: '#fff',
        borderRadius: theme.shape.borderRadius,
        boxShadow: theme.shadows[1],
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        '& > *': {
            margin: theme.spacing(0, 1),
        }
    }
}));

export default function ProductSerialLedgerPage() {
    const classes = useStyles();
    // State to store filter values
    const [filters, setFilters] = useState({
        startDate: null,
        endDate: null,
        manufacturerId: null,
        productId: null,
        distributorId: null,
        errorFilter: null
    });
    const [pageNumber, setPageNumber] = useState(1); // Paging state
    const pageSize = 1000; // Fixed page size

    // Handle pagination
    const handleNextPage = () => {
        setPageNumber((prev) => prev + 1);
        /*refetch({ ...filters, pageNumber: pageNumber + 1, pageSize });*/
    };

    const handlePreviousPage = () => {
        if (pageNumber > 1) {
            setPageNumber((prev) => prev - 1);
          /*  refetch({ ...filters, pageNumber: pageNumber - 1, pageSize });*/
        }
    };

    const handleFilterChange = (newFilters) => {
        setFilters(newFilters);
        setPageNumber(1); // Reset to first page on filter change
       /* refetch({ ...newFilters, pageNumber: 1, pageSize });*/
    };
    

    // Render the filter and table components
    return (
        <div className={classes.root}>
            <Container className={classes.content}>
                {/* Filters Section */}
                <div className={classes.section}>
                    <ProductSerialLedgerFilters 
                        filters={filters} 
                        onFiltersChange={handleFilterChange} 
                    />
                </div>

                {/* Table Section */}
                <Paper className={`${classes.section} ${classes.tableSection}`}>
                    <ProductSerialLedgerTable 
                        pageNumber={pageNumber} 
                        pageSize={pageSize} 
                        filters={filters} 
                    />
                </Paper>

                {/* Pagination Section */}
                <div className={classes.pagination}>
                    <Button 
                        onClick={handlePreviousPage} 
                        disabled={pageNumber === 1}
                        variant="outlined"
                        color="primary"
                    >
                        Previous
                    </Button>
                    <Typography variant="body1">
                        Page: {pageNumber}
                    </Typography>
                    <Button 
                        onClick={handleNextPage}
                        variant="outlined"
                        color="primary"
                    >
                        Next
                    </Button>
                </div>
            </Container>
        </div>
    );
}
