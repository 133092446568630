import React, { useEffect, useState } from "react";
import { TextField, MenuItem, Grid, Button, Typography, makeStyles } from "@material-ui/core";
import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

const useStyles = makeStyles((theme) => ({
    filterContainer: {
        padding: theme.spacing(3),
        backgroundColor: '#fff',
        borderRadius: theme.shape.borderRadius,
        boxShadow: theme.shadows[1],
    },
    sectionTitle: {
        fontWeight: 500,
        marginBottom: theme.spacing(2)
    },
    dropdown: {
        '& .MuiInputBase-root': {
            minHeight: 56, // Consistent height for dropdowns
        },
        '& .MuiSelect-select': {
            minHeight: 20, // Minimum height for the select content
        }
    },
    dateField: {
        '& .MuiInputBase-root': {
            height: 56, // Match dropdown height
        }
    },
    actionButton: {
        marginRight: theme.spacing(1),
        height: 36
    }
}));

const Manufacturer_QUERY = gql`
query getmanufacturers($filter: String) {
  admin {
    manufacturers(filter:$filter) {
      isDistributor
      address
      displayName
      logo
      manufacturerId
      profilePhoto
      userName
      uploadFrequency
      email
      totalFailed
      totalRequest
      totalSerial
      totalInstalled
      recentUpload
      userId
      isActive

    }
  }
}
`;

const Distributor_QUERY = gql`
query getdistributors($manufacturerId: Int) {
  admin {
    distributorList(manufacturerId:$manufacturerId) {
      distributorId
      distributorNames

    }
  }
}
`;

const Product_QUERY = gql`
query getproducts($manufacturerId: Int) {
  admin {
    productsList(manufacturerId:$manufacturerId) {
      productId
      model
      wattage

    }
  }
}
`;

export default function ProductSerialLedgerFilters({ filters, onFiltersChange }) {
    const classes = useStyles();
    const [models, setModels] = useState([]);
    const [suppliers, setSuppliers] = useState([]);

    // Query to fetch manufacturers
    const { data, loading: manufacturersLoading } = useQuery(Manufacturer_QUERY);

    // Lazy queries for models and suppliers
    const [fetchModels, { data: modelsData }] = useLazyQuery(Product_QUERY);

    const [fetchSuppliers, { data: suppliersData }] = useLazyQuery(Distributor_QUERY);

    // Update models when manufacturer changes
    useEffect(() => {
        if (filters.manufacturerId) {
            fetchModels({ variables: { manufacturerId: filters.manufacturerId } });
        } else {
            setModels([]); // Clear models when no manufacturer is selected
        }
    }, [filters.manufacturerId, fetchModels]);

    useEffect(() => {
        if (modelsData) {
            setModels(modelsData.admin.productsList.map((model) => model));
        } else {
            setModels([]);
        }
    }, [modelsData]);

    // Update suppliers when manufacturer or model changes
    useEffect(() => {
        if (filters.manufacturerId) {
            fetchSuppliers({ variables: { manufacturerId: filters.manufacturerId, productId: filters.productId || null } });
        }
    }, [filters.manufacturerId, filters.productId, fetchSuppliers]);

    useEffect(() => {
        if (suppliersData) {
            setSuppliers(suppliersData.admin.distributorList.map((supplier) => supplier));
        } else {
            setSuppliers([]);
        }
    }, [suppliersData]);

    const handleFilterChange = (field, value) => {
        // Convert empty strings to null for API calls
        const apiValue = value === "" ? null : value;
        
        // If manufacturer changes, reset dependent filters
        if (field === "manufacturerId") {
            onFiltersChange({
                ...filters,
                manufacturerId: apiValue,
                productId: null,      // Reset Model
                distributorId: null   // Reset Supplier
            });
        } else {
            onFiltersChange({ ...filters, [field]: apiValue });
        }
    };

    const resetFilters = () => {
        onFiltersChange({
            startDate: null,
            endDate: null,
            errorFilter: null,
            manufacturerId: null,
            productId: null,
            distributorId: null,
        });
    };

    return (
        <Grid container spacing={2} className={classes.filterContainer}>
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    {/* Date Range Filters - 2 columns */}
                    <Grid item xs={12} md={3}>
                        <TextField
                            className={classes.dateField}
                            size="small"
                            fullWidth
                            label="Start Date"
                            type="date"
                            value={filters.startDate || ""}
                            onChange={(e) => handleFilterChange("startDate", e.target.value)}
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TextField
                            className={classes.dateField}
                            size="small"
                            fullWidth
                            label="End Date"
                            type="date"
                            value={filters.endDate || ""}
                            onChange={(e) => handleFilterChange("endDate", e.target.value)}
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>

                    {/* Manufacturer Filter */}
                    <Grid item xs={12} md={3}>
                        <TextField
                            className={classes.dropdown}
                            size="small"
                            fullWidth
                            label="Manufacturer"
                            value={filters.manufacturerId || ""}
                            onChange={(e) => handleFilterChange("manufacturerId", e.target.value)}
                            select
                            disabled={manufacturersLoading}
                        >
                            <MenuItem value="">Select Manufacturer</MenuItem>
                            {data?.admin?.manufacturers?.map((manufacturer) => (
                                <MenuItem key={manufacturer.manufacturerId} value={manufacturer.manufacturerId}>
                                    {manufacturer.displayName}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>

                    {/* Error Filter */}
                    <Grid item xs={12} md={3}>
                        <TextField
                            className={classes.dropdown}
                            size="small"
                            fullWidth
                            label="Error Filter"
                            value={filters.errorFilter || ""}
                            onChange={(e) => handleFilterChange("errorFilter", e.target.value)}
                            select
                        >
                            <MenuItem value="">Select Error Type</MenuItem>
                            <MenuItem value="Brand Mismatch">Brand Mismatch</MenuItem>
                            <MenuItem value="Brand & Model Mismatch">Brand & Model Mismatch</MenuItem>
                            <MenuItem value="Brand, Model & Supplier Mismatch">
                                Brand, Model & Supplier Mismatch
                            </MenuItem>
                        </TextField>
                    </Grid>

                    {/* Model and Supplier Filters */}
                    <Grid item xs={12} md={3}>
                        <TextField
                            className={classes.dropdown}
                            size="small"
                            fullWidth
                            label="Model"
                            value={filters.productId || ""}
                            onChange={(e) => handleFilterChange("productId", e.target.value)}
                            select
                            disabled={!filters.manufacturerId}
                        >
                            <MenuItem value="">Select Model</MenuItem>
                            {models.map((model) => (
                                <MenuItem key={model.productId} value={model.productId}>
                                    {model.model}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TextField
                            className={classes.dropdown}
                            size="small"
                            fullWidth
                            label="Supplier"
                            value={filters.distributorId || ""}
                            onChange={(e) => handleFilterChange("distributorId", e.target.value)}
                            select
                            disabled={!filters.manufacturerId}
                        >
                            <MenuItem value="">Select Supplier</MenuItem>
                            {suppliers.map((supplier) => (
                                <MenuItem key={supplier.distributorId} value={supplier.distributorId}>
                                    {supplier.distributorNames}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>

                    {/* Reset Button */}
                    <Grid item xs={12} md={6} container alignItems="center">
                        <Button 
                            variant="outlined" 
                            color="secondary" 
                            onClick={resetFilters}
                            size="small"
                        >
                            Reset Filters
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}
