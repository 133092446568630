import React from 'react';
import _ from 'lodash';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { withStyles } from '@material-ui/core/styles';
const styles = theme => ({
    root: {
        width: '100%',
        overflowX: 'auto',
    },
    tableRow: {
        height: "auto"
    },
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
});
const InstalledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.grey[50],
        color: theme.palette.grey[900],
        border: "none",
        fontFamily: 'NHaasGroteskDSPro-65Md',
        fontSize: 14,
        verticalAlign: "middle",
        padding: theme.spacing(2),
        height: "auto",
        whiteSpace: " nowrap",
    },

}))(TableCell);


class HeaderInstalled extends React.Component {


    constructor(props) {
        super(props);
        this._id = _.uniqueId('model_row');

    }
    shouldComponentUpdate(nextProps, nextState) {
        return false;
    }
    render() {
        return (

            <TableRow key={this._id}>
                 <InstalledTableCell >Upload Id</InstalledTableCell>
                <InstalledTableCell>Serial Number</InstalledTableCell>
                <InstalledTableCell>Model</InstalledTableCell>
               
                <InstalledTableCell>Ledger Status</InstalledTableCell>
                <InstalledTableCell >Supplier</InstalledTableCell>
                <InstalledTableCell>Flash Data</InstalledTableCell>
               
               

            </TableRow>


        );




    }
}

export default withStyles(styles)(HeaderInstalled);
