import React, { useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { 
    Table, 
    TableBody, 
    TableCell, 
    TableContainer, 
    TableHead, 
    TableRow, 
    Paper,
    Typography
} from '@material-ui/core';
import { useQuery } from '@apollo/react-hooks';
import QueryHelper from '../../libs/QueryHelper';
import FbError from '../common/FbError';
import FbLoader from '../common/FbLoader';

const useStyles = makeStyles((theme) => ({
    tableContainer: {
        marginTop: theme.spacing(3),
        minHeight: 400, // Minimum height to prevent shrinking
        backgroundColor: '#fff',
        borderRadius: theme.shape.borderRadius,
        boxShadow: theme.shadows[1],
    },
    table: {
        minWidth: 650,
    },
    tableHeader: {
        backgroundColor: theme.palette.grey[100],
        '& th': {
            fontWeight: 600,
        }
    },
    emptyState: {
        minHeight: 400,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: theme.spacing(3),
        backgroundColor: '#fff',
        borderRadius: theme.shape.borderRadius,
        boxShadow: theme.shadows[1],
    }
}));

export default function ProductSerialLedgerTable({ pageNumber, pageSize, filters }) {
    const classes = useStyles();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [data, setData] = useState([]);

    // Fetch data using the filters
    const { data: queryData, loading: queryLoading, error: queryError } = useQuery(QueryHelper().LedgerListing(), {
        variables: {
            ...filters,
            pageNumber,
            pageSize,
        } // Use the filters as query variables
    });

    if (queryLoading) {
        return (
            <div className={classes.emptyState}>
                <FbLoader loaderStyle="center" />
            </div>
        );
    }

    if (queryError) {
        return (
            <div className={classes.emptyState}>
                <FbError message={queryError.message} errorStyle="center" />
            </div>
        );
    }

    if (!queryData || !queryData.admin.productSerialLedgerRecords || queryData.admin.productSerialLedgerRecords.length === 0) {
        return (
            <div className={classes.emptyState}>
                <Typography color="textSecondary">
                    No records found. Try adjusting your filters.
                </Typography>
            </div>
        );
    }

    return (
        <TableContainer component={Paper} className={classes.tableContainer}>
            <Table className={classes.table}>
                <TableHead>
                    <TableRow className={classes.tableHeader}>
                        <TableCell>Record ID</TableCell>
                        <TableCell>Serial</TableCell>
                        <TableCell>Brand</TableCell>
                        <TableCell>Model</TableCell>
                        <TableCell>Distributor</TableCell>
                        <TableCell>Batch ID</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Error</TableCell>
                        <TableCell>Ledger Upload Time</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {queryData.admin.productSerialLedgerRecords.map((record) => (
                        <TableRow key={record.recordId}>
                            <TableCell>{record.recordId}</TableCell>
                            <TableCell>{record.serial}</TableCell>
                            <TableCell>{record.brand}</TableCell>
                            <TableCell>{record.model}</TableCell>
                            <TableCell>{record.distributorName}</TableCell>
                            <TableCell>{record.batchId}</TableCell>
                            <TableCell>{record.status}</TableCell>
                            <TableCell>{record.error}</TableCell>
                            <TableCell>{record.createdTime}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
