import React, { useState } from 'react';
import { TableCell, TableRow, Avatar, Grid, List, ListItem, ListItemText, Typography, Dialog, DialogTitle, DialogContent, DialogContentText, Box, IconButton } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import { SerialImagePath } from '../../../constants';
import theme from '../../../theme';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import HistoryIcon from '@material-ui/icons/History';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import CloseIcon from '@material-ui/icons/Close';
import { useSnackbar } from 'notistack';
import { UploadHistoryProvider } from '../../uploadhistory/UploadHistoryContextProvider';
import IsolatedUploadHistoryItem from '../../uploadhistory/UploadHistoryNew';
import { useQuery } from '@apollo/react-hooks';
import QueryHelper from '../../../libs/QueryHelper';
import UserProfile from '../../auth/UserProfile';
import { Role_Admin, Role_DISTRIBUTOR, Role_DISTRIBUTOR_STAFF, Role_MANUFACTURER, Role_STAFF } from '../../../constants';
import PropTypes from 'prop-types';

const styles = {
    paperBox: {
        boxShadow: "none",
        border: "1px solid #ddd",
    },
    serialToggle: {
        width: 265,
    },
    seialNum: {
        color: "#333",
        padding: "0",
        fontSize: "18px",
        lineHeight: "normal",
        alignItems: "baseline",
        "&:hover": {
            background: "transparent",
        },
    },
    supplier: {
        color: "#333",
        padding: "0",
        fontSize: "13px",
        fontWeight: "bold",
        lineHeight: "normal",
        alignItems: "baseline",
        maxWidth: '300px',
        overflow: "hidden",
        textOverflow: "ellipsis",

        "&:hover": {
            background: "transparent",
        },
    },
    row: {
        '&:nth-of-type(even)': {
            backgroundColor: theme.palette.grey[50],
        },
    },
    Leftmargin: {
        marginRight: theme.spacing(2),
    },
    avatar: {
        borderRadius: 0,
        width: 42,
        height: 42,
        color: 'tranparent',
        marginRight: '6px'
    },
    inline: {
        display: 'inline',
        marginLeft: theme.spacing(1) * 0.5,
        fontFamily: 'NHaasGroteskDSPro-55Rg',
        color: theme.palette.grey[700],
    },
    listClass: {
        display: "flex",
        flexDirection: 'row',
        [theme.breakpoints.down('sm')]: {
            display: "inline-block",
        },
    },
    tag: {
        color: theme.palette.grey[900],
        fontFamily: 'NHaasGroteskDSPro-65Md',
    },
    paddingZero: {       
        padding: 0,
        textAlign:"right",
    },
    ledgerStatus: {
        display: 'flex',
        alignItems: 'start',
        gap: theme.spacing(1),
    },
    ledgerStatusText: {
        display: 'flex',
        flexDirection: 'column',
        fontSize: '0.875rem',
    },
    ledgerDate: {
        fontSize: '0.75rem',
        color: theme.palette.text.secondary,
    },
    successIcon: {
        color: theme.palette.success.main,
    },
    failureIcon: {
        color: theme.palette.error.main,
    },
};

const InstallTableCell = withStyles(theme => ({
    body: {
        padding: theme.spacing(2),
        borderBottomColor: theme.palette.grey[200],
        fontSize: 14,
        whiteSpace: " nowrap",
        '&:last-child': {
            paddingRight: 16,
        },
        [theme.breakpoints.down('sm')]: {
     
            display: "block",
            textAlign: "right",
            '&:before': {
                content: "attr(data-label)",
                float: "left",
                fontWeight: "bold",
                textTransform: " uppercase",
                lineHeight: "24px",
                marginRight: "16px",
            },
        },
    },
}))(TableCell);

const RowInstalled = ({ classes, serial, showSerialImagesCallback, manufacturerId }) => {
    const [isSwitchOn, setIsSwitchOn] = useState(false);
    const [open, setOpen] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const _id = _.uniqueId('s_installed');

    const getManufacturerId = (manufacturerId) => {
        return Role_Admin === UserProfile.getRole()
            || Role_DISTRIBUTOR === UserProfile.getRole()
            || Role_DISTRIBUTOR_STAFF === UserProfile.getRole() 
                ? (manufacturerId == null ? serial.manufacturerId : parseInt(manufacturerId)) 
                : parseInt(UserProfile.getManufacturerId());
    };

    const _getQueryVariables = (role, productSerialId, manufacturerId) => {
        switch (role) {
            case Role_DISTRIBUTOR_STAFF:
            case Role_DISTRIBUTOR:
                {
                    return { productSerialId: parseInt(productSerialId), manufacturerId: parseInt(manufacturerId) };
                }
            case Role_Admin:
                return { productSerialId: parseInt(productSerialId), manufacturerId: parseInt(manufacturerId) };
            case Role_MANUFACTURER:
            case Role_STAFF:
            default:
                return {
                    productSerialId: parseInt(productSerialId)
                };
        }
    };

    const _getData = (data, role) => {
        switch (role) {
            case Role_DISTRIBUTOR_STAFF:
            case Role_DISTRIBUTOR:
                return {
                    uploadHistory: data.supplier.historyForSerial
                };
            case Role_Admin:
                return {
                    uploadHistory: data.admin.historyForSerial
                };
            case Role_MANUFACTURER:
            case Role_STAFF:
            default:
                return {
                    uploadHistory: data.manufacturer.historyForSerial
                };
        }
    };

    const { loading, error, data, refetch } = useQuery(QueryHelper().SerialUploadHistory(), {
        variables: _getQueryVariables(UserProfile.getRole(), serial.productSerialId, getManufacturerId(manufacturerId)),
        fetchPolicy: "no-cache", 
        skip: !open
    });

    const uploadDataForSerial = !loading && !error && data && open ? _getData(data, UserProfile.getRole()) : null;

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text);
        const variant = 'success';
        enqueueSnackbar('Copied ' + text, { variant });
    };

    const handleDialogOpen = () => {
        setOpen(true);
        refetch();
    };

    const handleDialogClose = () => {
        setOpen(false);
    };

    const onDone = () => {
        handleDialogClose();
        refetch();
    };

    const fetchAndShowSerialImages = () => {
        const { serial: serialNumber, productSerialId, manufacturerId: manufacturerIdForSuperAdmin } = serial;
        showSerialImagesCallback(serialNumber, productSerialId, manufacturerIdForSuperAdmin);
    };

    const serialImage = serial.image ? SerialImagePath + serial.image : null;

    return (
        <>
            <TableRow className={classes.row} key={_id}>
            <InstallTableCell data-label="History">
                    {serial.uploadId}
                    <IconButton className={classes.button} aria-label="Upload History" color="primary" onClick={handleDialogOpen}>
                        <HistoryIcon /> 
                    </IconButton>
                    <IconButton className={classes.button} aria-label="Copy" color="primary" onClick={() => copyToClipboard(serial.uploadId)}>
                        <FileCopyIcon />
                    </IconButton>
                </InstallTableCell>
                <InstallTableCell data-label="Serial Number">
                    <Grid
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="center"
                        className={classes.serialToggle}
                    >
                        <Grid item>
                            <Avatar 
                                onClick={serial.image && fetchAndShowSerialImages} 
                                alt="Serial image" 
                                src={serialImage} 
                                className={classes.avatar} 
                                style={serial.image && { cursor: "pointer" }}
                            >
                                NA
                            </Avatar>
                        </Grid>
                        <Grid item>
                            <Typography variant="h4">
                                {serial.serial}
                            </Typography>
                        </Grid>
                    </Grid>
                </InstallTableCell>
               
                <InstallTableCell>
                    {serial.model}
                </InstallTableCell>
                <InstallTableCell data-label="Ledger Status">
                    <div className={classes.ledgerStatus}>
                        {serial.ledgerStatus ? (
                            <CheckCircleIcon className={classes.successIcon} />
                        ) : (
                            <CancelIcon className={classes.failureIcon} />
                        )}
                        <div className={classes.ledgerStatusText}>
                            <span>{serial.ledgerStatus ? 'Uploaded' : 'Not Uploaded'}</span>
                            {serial.ledgerDate && (
                                <span className={classes.ledgerDate}>
                                   Ledger Uploaded on:    {serial.ledgerDate}
                                </span>
                            )}
                            Supplied on: {serial.uploadedOn}
                        </div>
                    </div>
                </InstallTableCell>
                <InstallTableCell>
                    <Typography component="span" className={classes.supplier} color="textPrimary">
                        {serial.supplier ? serial.supplier : "Self supplier"}
                    </Typography>
                </InstallTableCell>
                <InstallTableCell>
                    <List className={classes.root} disablePadding classes={{
                        root: classes.listClass
                    }}>
                        {['pmax', 'ff', 'voc', 'isc', 'vpm', 'ipm'].map(key => (
                            <ListItem key={key} alignItems="flex-start" disableGutters={true}>
                                <ListItemText
                                    secondary={
                                        <React.Fragment>
                                            <span className={classes.tag}>{`${key.toUpperCase()}:`}</span>
                                            <Typography component="span" className={classes.inline} color="textPrimary">
                                                {serial[key]}
                                            </Typography>
                                        </React.Fragment>
                                    }
                                    classes={{ root: classes.paddingZero }}
                                />
                            </ListItem>
                        ))}
                    </List>
                </InstallTableCell>
            </TableRow>

            <Dialog open={open} onClose={handleDialogClose} aria-labelledby="history-dialog-title">
                <DialogTitle id="history-dialog-title">
                    <Box display="flex" alignItems="center">
                        <Box flexGrow={1}>Upload History</Box>
                        <Box>
                            <IconButton onClick={handleDialogClose}>
                                <CloseIcon />
                            </IconButton>
                        </Box>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    {loading && <DialogContentText>Loading...</DialogContentText>}
                    {error && <DialogContentText>Error loading data</DialogContentText>}
                    {!loading && !error && data && (
                        <DialogContentText>
                            <UploadHistoryProvider 
                                onDone={onDone} 
                                refetch={refetch} 
                                classes={classes} 
                                manufacturerId={manufacturerId}
                            >
                                <IsolatedUploadHistoryItem 
                                    single={true} 
                                    upload={uploadDataForSerial?.uploadHistory} 
                                />
                            </UploadHistoryProvider>
                        </DialogContentText>
                    )}
                </DialogContent>
            </Dialog>
        </>
    );
};

RowInstalled.propTypes = {
    classes: PropTypes.object.isRequired,
    serial: PropTypes.object.isRequired,
    showSerialImagesCallback: PropTypes.func.isRequired,
    manufacturerId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default withStyles(styles)(RowInstalled);
