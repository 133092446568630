import { withStyles } from '@material-ui/core/styles';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import _ from 'lodash';
import React from 'react';
const styles = theme => ({
   
    errorMessage: {

        padding: "10px 0",
        textAlign: "center",
        color: theme.palette.error.main,
        display:"block"
         
    }
  
});


class FbError extends React.Component {
    constructor(props) {
        super(props);
        this._id = _.uniqueId(this.props.Prefix ? this.props.Prefix : "loader");
        this.errorStyle = this.props.errorStyle != null ? this.props.errorStyle : 'default';
    
    }

    shouldComponentUpdate(nextProps, nextState) {
        return false;
    }

    render() {
        const { classes } = this.props;
        const message = this.props.message != null ? this.props.message : "OOPS.. Some error has occured, please retry ";
        const style = { "textAlign": "center" }
        const colspan = this.props.colSpan != null ? this.props.colSpan : 1;
        
        const base_message = (<span className={classes.errorMessage}>{message}</span>);
        if (this.errorStyle === 'default') {
            return base_message;
        }
        else if (this.errorStyle === 'emptycell') {
            const common_td = (<TableRow><TableCell colSpan={colspan} style={style}> {base_message}</TableCell></TableRow>);
            if (this.props.isTableBodyRequired === true) {
                return (<TableBody>{common_td}</TableBody>);
            }
            return common_td;
        }
        else if (this.errorStyle === 'tableLoader') {
            const common_td = (<TableRow><TableCell colSpan={colspan} style={style}> {base_message}</TableCell></TableRow>);
            return (
                <table>
                    <tbody>{common_td}</tbody>
                </table>
            );
        }

        return base_message;
    }
}
export default withStyles(styles)(FbError);
