import React from 'react';
import _ from 'lodash';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { withStyles } from '@material-ui/core/styles';
const styles = theme => ({
    root: {
        width: '100%',
        overflowX: 'auto',
    },
    tableRow: {
        height: "auto"
    },
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
});
const AvailableTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.grey[50],
        color: theme.palette.grey[900],
        border: "none",
        fontFamily: 'NHaasGroteskDSPro-65Md',
        fontSize: 14,
        verticalAlign: "middle",
        padding: theme.spacing(2),
        height: "auto",
        whiteSpace: " nowrap",
    },

}))(TableCell);


class HeaderAvailable extends React.Component { 


    constructor(props) {
        super(props);
        this._id = _.uniqueId('serial_model_row');

    }
    shouldComponentUpdate(nextProps, nextState) {
        return false;
    }
    render() {
       
        return (

            <TableRow key={this._id}>
                <AvailableTableCell rowSpan="2">Upload Id</AvailableTableCell>
                
                <AvailableTableCell rowSpan="2">Serial Number</AvailableTableCell>
                <AvailableTableCell >Model</AvailableTableCell>
                <AvailableTableCell rowSpan="1">Ledger Status</AvailableTableCell>
                <AvailableTableCell >Supplier</AvailableTableCell>
                <AvailableTableCell >Flash Data</AvailableTableCell>
               
            </TableRow>


        );




    }
}

export default withStyles(styles)(HeaderAvailable);
