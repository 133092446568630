import React, { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Box, Button, TableCell, Tooltip, Grid, IconButton, List, ListItem, ListItemText, TableRow, Typography, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import HistoryIcon from '@material-ui/icons/History';
import QueryHelper from '../../../libs/QueryHelper';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';

import CloseIcon from '@material-ui/icons/Close';
import _ from 'lodash';
import { blue800 } from 'material-ui/styles/colors';
import UserProfile from '../../auth/UserProfile';
import Snackbar from '@material-ui/core/Snackbar';
import { useSnackbar } from 'notistack';
import IsolatedUploadHistoryItem from '../../uploadhistory/UploadHistoryNew';
import { UploadHistoryProvider } from '../../uploadhistory/UploadHistoryContextProvider';
import theme from '../../../theme';
import { LINKS_PER_PAGE, Role_Admin, Role_DISTRIBUTOR, Role_DISTRIBUTOR_STAFF, Role_MANUFACTURER, Role_STAFF } from '../../../constants';
const styles = {
    serialToggle: {
        width: 250,
    },
    seialNum: {
        color: "#333",
        padding: "0",
        fontSize: "16px",
        lineHeight: "normal",
        alignItems: "baseline",
        "&:hover": {
            background: "transparent",
        },
    },
    supplier: {
        color: "#333",
        padding: "0",
        fontSize: "13px",
        fontWeight: "bold",
        lineHeight: "normal",
        alignItems: "baseline",
        maxWidth: '300px',
        overflow: "hidden",
        textOverflow: "ellipsis",
        "&:hover": {
            background: "transparent",
        },
    },
    row: {
        '&:nth-of-type(even)': {
            backgroundColor: theme.palette.grey[100],
        },
    },
    Leftmargin: {
        marginRight: theme.spacing(2),
    },
    avatar: {
        borderRadius: 0,
        width: 42,
        height: 'auto',
        color: 'transparent'
    },
    inline: {
        display: 'inline',
        marginLeft: theme.spacing(1) * 0.5,
        fontFamily: 'NHaasGroteskDSPro-55Rg',
        color: theme.palette.grey[700],
    },
    listClass: {
        display: "flex",
        flexDirection: 'row',
        [theme.breakpoints.down('sm')]: {
            display: "inline-block",
        },
    },
    tag: {
        color: theme.palette.grey[900],
        fontFamily: 'NHaasGroteskDSPro-65Md',
    },
    paddingZero: {
        padding: 0,
        textAlign: "right",
    },
    button: {
        marginLeft: theme.spacing(1) * 0.6,
        verticalAlign: "top",
        padding: 0,
        '&:hover': {
            background: "transparent",
        }
    },
    ledgerStatus: {
        display: 'flex',
        alignItems: 'start',
        gap: theme.spacing(1),
    },
    ledgerStatusText: {
        display: 'flex',
        flexDirection: 'column',
        fontSize: '0.875rem',
    },
    ledgerDate: {
        fontSize: '0.75rem',
        color: theme.palette.text.secondary,
    },
    successIcon: {
        color: theme.palette.success.main,
    },
    failureIcon: {
        color: theme.palette.error.main,
    },
};

const AvailableTableCell = withStyles(theme => ({
    body: {
        padding: theme.spacing(2),
        borderBottomColor: theme.palette.grey[200],
        fontSize: 14,
        whiteSpace: "nowrap",
        '&:last-child': {
            paddingRight: 16,
        },
        [theme.breakpoints.down('sm')]: {
            display: "block",
            textAlign: "right",
            '&:before': {
                content: "attr(data-label)",
                float: "left",
                fontWeight: "bold",
                textTransform: "uppercase",
                lineHeight: "24px",
                marginRight: "16px",
            },
        },
    },
}))(TableCell);

const RowAvailable = ({ classes, serial, manufacturerId }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [isSwitchOn, setIsSwitchOn] = useState(false);
    const [copySuccess, setCopySuccess] = useState('');
    const [dialogOpen, setDialogOpen] = useState(false);
    const _id = _.uniqueId('s_avail');
    //const [snackbarBarState, setSnackbarState] = React.useState({
    //    snackBarOpen: false,
    //    vertical: 'top',
    //    horizontal: 'left',
    //    snackBarMessage: 'copied!'
    //});
   // const { vertical, horizontal, snackBarOpen, snackBarMessage } = snackbarBarState;
    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text);
        const variant = 'success';
       // setCopySuccess('Copied!');
        // setSnackbarState({ ...snackbarBarState, 'snackBarOpen': true })
        enqueueSnackbar('Copied ' + text, { variant });
    };
    //const handleSnakbarClose = () => {
    //   // setSnackbarState({ ...snackbarBarState, 'snackBarOpen': false });
    //};
    const handleDialogOpen = () => {
        setDialogOpen(true);
        refetch();
    };
    const getManufacturerId = (manufacturerId) => {
        return Role_Admin === UserProfile.getRole()
            || Role_DISTRIBUTOR === UserProfile.getRole()
            || Role_DISTRIBUTOR_STAFF === UserProfile.getRole() ? (manufacturerId == null ? serial.manufacturerId : parseInt(manufacturerId)) : parseInt(UserProfile.getManufacturerId());
    };
    const _getQueryVariables = (role,productSerialId, manufacturerId) => {
        switch (role) {
            case Role_DISTRIBUTOR_STAFF:
            case Role_DISTRIBUTOR:
                {
                    return { productSerialId: parseInt(productSerialId), manufacturerId: parseInt(manufacturerId) };
                }
            case Role_Admin:
                return { productSerialId: parseInt(productSerialId), manufacturerId: parseInt(manufacturerId) };
            case Role_MANUFACTURER:
            case Role_STAFF:
            default:
                return {
                    productSerialId: parseInt(productSerialId)
                };
        }
    };
    const onDone = () => {
        handleDialogClose();
        refetch();

    }
    const _getData = (data, role) => {
        switch (role) {
            case Role_DISTRIBUTOR_STAFF:
            case Role_DISTRIBUTOR:
                return {
                    
                    uploadHistory: data.supplier.historyForSerial
                };
            case Role_Admin:
                return {
                  
                    uploadHistory: data.admin.historyForSerial
                };
            case Role_MANUFACTURER:
            case Role_STAFF:
            default:
                return {
                   
                    uploadHistory: data.manufacturer.historyForSerial
                };
        }
    };
    const handleDialogClose = () => {
        setDialogOpen(false);
    };
    const { loading, error, data, refetch } = useQuery(QueryHelper().SerialUploadHistory(), {
        variables: _getQueryVariables(UserProfile.getRole(), serial.productSerialId, getManufacturerId(manufacturerId)),
        fetchPolicy: "no-cache", skip: !dialogOpen
    });
    const uploadDataForSerial = !loading && !error && data && dialogOpen ? _getData(data, UserProfile.getRole()) : null;
    console.log(uploadDataForSerial);
   
    return (
        <>
            
            <TableRow className={classes.row} key={_id}>
                <AvailableTableCell data-label="History">
                    {serial.uploadId}
                    <IconButton className={classes.button} aria-label="Upload History" color="primary" onClick={handleDialogOpen}>
                        <HistoryIcon /> 
                    </IconButton>
                    <IconButton className={classes.button} aria-label="Copy" color="primary" onClick={() => copyToClipboard(serial.uploadId)}>
                        <FileCopyIcon />
                    </IconButton>
                </AvailableTableCell>
              
                <AvailableTableCell data-label="Serial Number">
                    <Grid
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="center"
                        className={classes.serialToggle}
                    >
                        <Grid item>
                            <Tooltip title="Click to view serial" placement="bottom">
                                <Button component="h3" color="primary" className={classes.seialNum}
                                    onClick={() => setIsSwitchOn(!isSwitchOn)}>
                                    {isSwitchOn ? (
                                        <div>
                                            {serial.serial}
                                            <div style={{ color: blue800, fontSize: '12px' }}>Click to hide</div>
                                        </div>
                                    ) : (
                                        <div>
                                            XXXX-XXXX-XXXX
                                            <div style={{ color: blue800, fontSize: '12px' }}>Click to view</div>
                                        </div>
                                    )}
                                </Button>
                            </Tooltip>
                            {isSwitchOn && (
                                <IconButton className={classes.button} aria-label="copy" color="primary" onClick={() => copyToClipboard(serial.serial)}>
                                    <FileCopyIcon />
                                </IconButton>
                            )}
                            <label>{copySuccess}</label>
                        </Grid>
                    </Grid>
                </AvailableTableCell>
                <AvailableTableCell>{serial.model}</AvailableTableCell>
                
                <AvailableTableCell data-label="Ledger Status">
                <div className={classes.ledgerStatus}>
                        {serial.ledgerStatus ? (
                            <CheckCircleIcon className={classes.successIcon} />
                        ) : (
                            <CancelIcon className={classes.failureIcon} />
                        )}
                        <div className={classes.ledgerStatusText}>
                            <span>{serial.ledgerStatus ? 'Uploaded' : 'Not Uploaded'}</span>
                            {serial.ledgerDate && (
                                <span className={classes.ledgerDate}>
                                Ledger Uploaded on:    {serial.ledgerDate}
                                </span>
                            )}
                            Supplied on: {serial.uploadedOn}
                        </div>
                    </div>
                </AvailableTableCell>
                <AvailableTableCell>
                    <Typography component="span" className={classes.supplier} color="textPrimary">
                        {serial.supplier ? serial.supplier : "Self supplier"}
                    </Typography>
                </AvailableTableCell>
                <AvailableTableCell>
                    <List className={classes.root} disablePadding classes={{ root: classes.listClass }}>
                        {['pmax', 'ff', 'voc', 'isc', 'vpm', 'ipm'].map(key => (
                            <ListItem alignItems="flex-start" disableGutters={true} key={key}>
                                <ListItemText
                                    secondary={
                                        <React.Fragment>
                                            <span className={classes.tag}>{`${key.toUpperCase()}:`}</span>
                                            <Typography component="span" className={classes.inline} color="textPrimary">
                                                {serial[key]}
                                            </Typography>
                                        </React.Fragment>
                                    }
                                    classes={{ root: classes.paddingZero }}
                                />
                            </ListItem>
                        ))}
                    </List>
                </AvailableTableCell>
            </TableRow>
            <Dialog open={dialogOpen} onClose={handleDialogClose} aria-labelledby="history-dialog-title">
                <DialogTitle id="history-dialog-title">
                    <Box display="flex" alignItems="center">
                        <Box flexGrow={1} >Upload History</Box>
                        <Box>
                            <IconButton onClick={handleDialogClose}>
                                <CloseIcon />
                            </IconButton>
                        </Box>
                    </Box>

                </DialogTitle>
                <DialogContent>
                    {loading && <DialogContentText>Loading...</DialogContentText>}
                    {error && <DialogContentText>Error loading data</DialogContentText>}
                    {!loading && !error && data && (
                        <DialogContentText>
                            {/* Render your data here  */}
                            {
                                <UploadHistoryProvider onDone={onDone} refetch={refetch} classes={classes} manufacturerId={manufacturerId}>
                                    <IsolatedUploadHistoryItem single={ true} upload={uploadDataForSerial.uploadHistory} />
                                </UploadHistoryProvider>
                            }
                        </DialogContentText>
                    )}
                </DialogContent>
             
            </Dialog>
        </>
    );
};

export default withStyles(styles)(RowAvailable);
